import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import LogOut from "../../api/LogOutAPI";
import { selectClient } from "../../rdx/ClientSlice";
import { ReactComponent as SideBarLogo } from "../../Wallet_LOGO.svg";

export default (props) => {
  const [time, setTime] = useState(DateTime.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(DateTime.now());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const client = useSelector(selectClient);

  const history = useHistory();

  return (
    <div className="sideMenu">
      <div className="logo">
        <SideBarLogo></SideBarLogo>
      </div>
      <div className="footer">
        <div className="user">
          <div className="emoji">👤</div>
          <div>Hello,</div>
          <div>{client.username}</div>
        </div>
        <div className="logout">
          <div
            onClick={() => {
              LogOut()
                .then((ok) => {
                  console.log("OK");
                  history.push("/login");
                })
                .catch((err) => {
                  alert(err);
                });
            }}
          >
            Logout
          </div>
        </div>
        <div className="time">
          <div>{time.toFormat("dd|M|yyyy")}</div>
          <div>{time.toFormat("ttt ")}</div>
        </div>
      </div>
    </div>
  );
};
