export const tableHeaders = <thead>
<tr className="headerRow">
  <th>Date</th>
  <th>Currency</th>
  <th>Revenue</th>
  <th>ARPU</th>
  <th>Total Bundles Sold</th>
</tr>
</thead>


export const tableRow = (dataRow) => {
    return (
      <tr>
        <td>{dataRow.date}</td>
        <td>{dataRow.currency}</td>
        <td>{parseFloat(dataRow.amount / 100).toFixed(2)}</td>
        <td>{parseFloat(dataRow.arpu / 100).toFixed(2)}</td>
        <td>{dataRow.totalBundlesSold}</td>
      </tr>
    );
  };

 export const filterWidget = (Title,dir, filterKey,onClick) => {
    return (
      <div className="sortBy">
        <div
          className="revenue"
          onClick={() => {
            onClick(filterKey);
          }}
        >
          {Title} {dir}
        </div>
      </div>
    );
  };