import { useState } from "react"

export default (props) => {

    const [code,setCode] = useState("")

    return(<div className="tfaPopUp">

        <div className="title">Two Factor Auth</div>

        <div className="text">{props.text}</div>

        <input onChange={(event)=>setCode(event.target.value)} value={code} placeholder="Multi Factor Code"></input>

        <button onClick={()=>props.callback(code)}>Submit</button>

    </div>)

}