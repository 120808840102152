import { useState } from "react"

export default (props) => {

    const [code,setCode] = useState("")

    return(<div className="PopUp">

        <div className="title">{props.title}</div>

        <div className="text">{props.text}</div>

        <button onClick={()=>props.callback()}>Close</button>

    </div>)

}