import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DashboardPage from "./features/dash/DashboardPage";
import LoginPage from "./features/login/LoginPage";


function App() {
  const root = document.getElementById("root");

  root.style.setProperty(
    "--bg-color",
    "linear-gradient(135deg,#6632D9,#2D16E0)"
  );
  root.style.setProperty("--logo-main-color", "#5431DE");
  root.style.setProperty("--logo-middle-color", "#5E3DE0");
  root.style.setProperty("--light-grey-color", "#FAF8F8");
  root.style.setProperty("--input-bg-color","#FAF8F8")
  root.style.setProperty("--input-placeholder-color","#000000")
  root.style.setProperty("--txt-color-primary","#6632D9");
  root.style.setProperty("--btn-txt-color","white");
  root.style.setProperty("--side-menu-color","#FAF8F8");
  root.style.setProperty("--side-menu-time-color","#8E8E8E");
  root.style.setProperty("--table-bg-color","#FAF8F8");
  root.style.setProperty("--table-header-row-color","#E6E6E6");



  return (
    <div className="App">


      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginPage}></Route>
          <Route exact path="/" component={DashboardPage}></Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
