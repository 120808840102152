import { createSlice } from "@reduxjs/toolkit";

export const clientSlice = createSlice({
    name:"client",
    initialState:{client:{username:"",email:"",brands:[]}},
    reducers:{
        setClient: (state,action)=>{
            state.client = action.payload;
        }
    }
})

export default clientSlice.reducer;
export const {setClient} = clientSlice.actions;
export const selectClient = (state) => state.client.client