import axios from "axios"

export default (conf) => {

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        withCredentials:true
    })

    instance.interceptors.request.use((config)=>{
        console.log(JSON.stringify(config))
        return config;
    })

    instance.interceptors.response.use(response=>{

        console.log(response.data)
        return Promise.resolve(response)

    },err=>{
        console.error(JSON.stringify(err))

        if(conf?conf.redirect401:true && err.response.status==401){
            console.log("Redirecting")
            window.location="/login"
        }

        return Promise.reject(err.response)

    })


    return instance;

}