import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import login from "../../api/LoginAPI";
import { setClient } from "../../rdx/ClientSlice";
import { ReactComponent as LoginLogo } from "../../Wallet_LOGO.svg";
import { ReactComponent as BackgroundLogo } from "../../Wallet_LOGO_White.svg";
import PopUp from "./PopUp";
import TFAPopUp from "./TFAPopUp";

const LoginPage = (props) => {
  const [form, setForm] = useState({
    username: "",
    password: "",
    twoFactorCode: null,
  });
  const [popUp, setPopUp] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogin = (twoFactorCode) => {
    login(form.username, form.password, twoFactorCode)
      .then((ok) => {
        console.log("Logged In");
        dispatch(setClient(ok.data));
        history.push("/");
      })
      .catch((err) => {
        console.error(JSON.stringify(err));

        if (err.status == 401) {
          if (err.data == "2FA_REQUIRED") {
            setPopUp(
              <TFAPopUp
              text={`A Multi Factor Authorization code has been sent to ${form.username} if they exist. Enter it below.`}
                callback={(code) => {
                  setPopUp(null);
                  handleLogin(code)
                }}
              ></TFAPopUp>
            );
          } else if (err.data =="INVALID_2FA_CODE"){
            console.error("INVALID 2FA!")
            setPopUp(
              <TFAPopUp
                text="The Code Provided Was Invalid."
                callback={(code) => {
                  setPopUp(null);
                  handleLogin(code)
                }}
              ></TFAPopUp>
            );
          } else {
            console.log(err);
            setPopUp(
              <PopUp
                title="Uh Oh!"
                text={`We could not log you in due to : ${err.data}`}
                callback={() => setPopUp(null)}
              ></PopUp>
            );
          }
        } else {
          setPopUp(
            <PopUp
              title="Uh Oh!"
              text={`We could not log you in due to : ${err.data?err.data:"UNKNOWN"}`}
              callback={() => setPopUp(null)}
            ></PopUp>
          );
        }
      })
      .finally(() => {
        setForm({ ...form, twoFactorCode: null });
      });
  };

  // useEffect(() => {
  //   if (form.username !== "" && form.password !== "") {
  //     handleLogin();
  //   }
  // }, [form.twoFactorCode]);

  return (
    <div id="LoginPage">
      <BackgroundLogo id="BG_LOGO"></BackgroundLogo>
      {popUp !== null ? <div className="popup tfa">{popUp}</div> : <div />}

      <div className="lhs">
        <div className="loginForm">
          <LoginLogo className="logo"></LoginLogo>
          <input
            id="wallet-client-username"
            className="input"
            placeholder="Username"
            type="text"
            name="wallet-client-username"
            onChange={(event) => {
              setForm({ ...form, username: event.target.value });
            }}
          ></input>
          <input
            id="wallet-client-password"
            className="input"
            placeholder="Password"
            type="password"
            name="wallet-client-password"
            onChange={(event) => {
              setForm({ ...form, password: event.target.value });
            }}
          ></input>
          <button onClick={() => handleLogin()}>Log In</button>
        </div>
      </div>
      <div className="rhs">
        <div className="welcome fadeIn">Welcome</div>
      </div>
    </div>
  );
};

export default LoginPage;
