import axios from "../app/axios";

export const getRevenueForBrand = (brandId, filters,paging) => {

  
   const urlParams = new URLSearchParams();
   urlParams.append("timeSpan",filters.timeSpan[1]);

   urlParams.append("fromUnix",filters.fromUnix);

   if(filters.revenue!=null){
    urlParams.append("sort",`amount,${filters.revenue}`)
   }

   if(filters.currency!=null){
    urlParams.append("sort",`currencyCode,${filters.currency}`)
   }

   if(filters.arpu!=null){
    urlParams.append("sort",`arpu,${filters.arpu}`)
   }
   
   if(filters.totalSold!=null){
    urlParams.append("sort",`totalSold,${filters.totalSold}`)
   }

   urlParams.append("page",paging.page);
   urlParams.append("size",paging.size);
  

 
   var request = {
     params:urlParams
   }

  return axios().get(`/revenue/brand/${brandId}`,request);
};
