import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import { getRevenueForBrand } from "../../api/RevenueAPI";
import { DAILY, TimeSpans } from "../../app/timeSpan";
import { tableHeaders, tableRow } from "./RevenueTableParts";

export default (props) => {
  const [expanded, setExpanded] = useState(props.expanded?true:false);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(null);

  const [error, setError] = useState(false);

  const [paging, setPaging] = useState({ page: 0, size: 10 });

  const [pageResponse, setPageResponse] = useState({
    isLast: false,
    isFirst: true,
    totalPages: 0,
  });

  const setFilters = (filterKey) => {
    const curr = filter[filterKey];
    setFilter({
      ...filter,
      [filterKey]: curr === null ? "ASC" : curr === "ASC" ? "DESC" : null,
    });
  };

  const mFilterWidget = (Title, filterKey) => {
    return (
      <div className="sortBy">
        <div
          className="revenue"
          onClick={() => {
            setFilters(filterKey);
          }}
        >
          {Title}{" "}
          {filter[filterKey] === null
            ? "-"
            : filter[filterKey] === "ASC"
            ? "▲"
            : "▼"}
        </div>
      </div>
    );
  };

  useEffect(() => {
    console.log("PAGE RESPONSE");
  }, [pageResponse]);

  const [filter, setFilter] = useState({
    timeSpan: DAILY,
    fromUnix: Math.round(
      DateTime.now()
        .toUTC()
        .minus(Duration.fromObject({ years: 3 }))
        .toMillis() / 1000
    ),
    revenue: null,
    currency: null,
    arpu: null,
    totalSold: null,
  });

  useEffect(() => {
    if (expanded) {
      getRevenueForBrand(props.brandId, filter, paging)
        .then((response) => {
          setData(response.data.content);
          setPageResponse({
            isFirst: response.data.first,
            isLast: response.data.last,
            totalPages: response.data.totalPages,
            totalElements : response.data.totalElements
          });
        })
        .catch((err) => {
          alert(JSON.stringify(err.data));
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter, paging]);

  if (data == null && !loading && expanded && !error) {
    console.log("Fetch Data!");
    getRevenueForBrand(props.brandId, filter, paging)
      .then((response) => {
        setData(response.data.content);
        setPageResponse({
          isFirst: response.data.first,
          isLast: response.data.last,
          totalPages: response.data.totalPages,
          totalElements : response.data.totalElements
        });
      })
      .catch((err) => {
        alert(JSON.stringify(err.data));
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(true);
  }

  const timeSpanOpts = TimeSpans.map((it) => (
    <div
      className="child"
      onClick={() => {
        console.log(it);
        setFilter({ ...filter, timeSpan: it });
        setPaging({...paging,page:0})
      }}
    >
      {it[0]}
    </div>
  ));

  const revenueRows = data ? data.map((it) => tableRow(it)) : null;

  console.log(props.brandProperties)
  
  const style = props.brandProperties
  ?{backgroundImage:`linear-gradient(90deg,${props.brandProperties.primaryColour},
    ${props.brandProperties.primaryColour},
    ${props.brandProperties.secondaryColour})`}
  :{backgroundImage:`linear-gradient(90deg,#6632D9,#2D16E0)`}

  return (
    <div className="revenueTableContainer">
      <div className={`titleBar ${expanded?"expanded":""}`} 
      style={style}
      onClick={() => setExpanded(!expanded)}>
        <h3>{props.brandName}</h3>
      </div>
      <div className="tableAndFilterWrapper">
        <div className={`filter ${expanded ? "expanded" : "compacted"}`}>
          <div className="sortBy">
            <div className="timeSpan">
              {filter.timeSpan[0]}
              <div className="childHolder">{timeSpanOpts}</div>
            </div>
          </div>
          {mFilterWidget("Revenue", "revenue")}
          {mFilterWidget("Currency", "currency")}
          {mFilterWidget("ARPU", "arpu")}
          {mFilterWidget("Total Sold", "totalSold")}
          <div className="paging">
            <button
              className="pagingBtn"
              disabled={pageResponse.isFirst}
              onClick={() => setPaging({ ...paging, page: paging.page - 1 })}
            >
              PREVIOUS
            </button>

            <div className="pagingNums">
              {paging.page + 1} / {pageResponse.totalPages} of {pageResponse.totalElements}
            </div>

            <button
              className="pagingBtn"
              disabled={pageResponse.isLast}
              onClick={() => setPaging({ ...paging, page: paging.page + 1 })}
            >
              NEXT
            </button>

            <input
              type="number"
              placeholder="size"
              value={paging.size}
              onChange={(event) => {
                setPaging({ ...paging, size: event.target.value, page: 0 });
              }}
            ></input>
          </div>
        </div>
        <div
          className={`tableContainer ${expanded ? "expanded" : "compacted"}`}
        >
          <table>
            {tableHeaders}
            <tbody>{revenueRows ? revenueRows : "..."}</tbody>
          </table>
        </div>
      </div>
      {/* <div className={`bottomBar ${expanded?"expanded":""}`} 
      style={style}
      onClick={() => setExpanded(!expanded)}>
      </div> */}
    </div>
  );
};
