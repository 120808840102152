import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhoAmI from "../../api/WhoAmIAPI";
import { selectClient, setClient } from "../../rdx/ClientSlice";
import SideMenu from "../menus/SideMenu";
import RevenueTable from "./RevenueTable";

export default (props) => {
  const dispatch = useDispatch();

  const client = useSelector(selectClient);

  useEffect(() => {
    WhoAmI()
      .then((response) => {
        console.log(response.data);
        dispatch(setClient(response.data));
      })
      .catch((err) => {});
  }, []);

  const brandRevenueTables = client.brands.map((it,index) => (
    <RevenueTable 
    brandName={it.brandName} 
    brandId={it.brandId} 
    brandProperties={it.properties}
    expanded={index < 1}>
    </RevenueTable>
  ));

  return (
    <div id="DashboardPage">
      <div className="lhs">
        <SideMenu></SideMenu>
      </div>
      <div className="rhs">
        <div className="header">
          <div className="yourDashTxt">Your Dashboard</div>
          <div className="welcome">Welcome, {client.username}</div>
          </div>
        <div className="tables">
          {brandRevenueTables}
          </div>
      </div>
    </div>
  );
};
